import React from 'react';
import Main from '../containers/Layout';
import termsAndConditionsConsumerAppContent from '../../content/pages/terms-and-conditions-fena-consumer-app.yml';
import TermsConsumerAppContainer from '@/containers/Terms-and-conditions-consumer-app';

export interface TermsAndConditionsConsumerAppPageContent {
  header: {
    title1: string;
    title2: string;
    subtitle1: string;
    subtitle2: string;
  };
  body: string;
}
const TermsAndConditionsConsumerAppPage: React.FunctionComponent = () => {
  const content =
    termsAndConditionsConsumerAppContent as unknown as TermsAndConditionsConsumerAppPageContent;
  return (
    <Main>
      <TermsConsumerAppContainer content={content} />
    </Main>
  );
};

export default TermsAndConditionsConsumerAppPage;
